
import { isBoolean, isFunction } from 'lodash'
import * as Yup from 'yup'
import useI18n from './useI18n'

export const yesNoFromBoolean = (value) => {
  // eslint-disable-next-line no-nested-ternary
  return isBoolean(value) ? (value ? 'yes' : 'no') : ''
}

export default function useValidators() {
  const { t } = useI18n()

  const yupString = Yup.string(t('forms.errors.validValue')).notOneOf(
    ['undefined'],
    t('forms.errors.validValue')
  )
  const yupNumber = Yup.number(t('forms.errors.validValue'))
  const yupBoolean = Yup.boolean(t('forms.errors.validValue'))
  const yupArray = Yup.array(t('forms.errors.validValue'))

  return {
    yupString,
    yupNumber,
    yupBoolean,
    yupArray,
    username: yupString
      .min(4, t('forms.errors.min4CharactersLong'))
      .max(32, t('forms.errors.max32CharactersLong'))
      .matches(/^[_.@A-Za-z0-9]*$/g, t('forms.errors.username'))
      .required(t('forms.errors.required')),
    legacyUsername: yupString
      .min(4, t('forms.errors.min4CharactersLong'))
      .max(32, t('forms.errors.max32CharactersLong'))
      .matches(/^[_.@+A-Za-z0-9]*$/g, t('forms.errors.username'))
      .required(t('forms.errors.required')),
    password: yupString
      .min(8, t('forms.errors.min8CharactersLong'))
      .max(32, t('forms.errors.max32CharactersLong'))
      .required(t('forms.errors.required')),
    confirmPassword: yupString
      .min(8, t('forms.errors.min8CharactersLong'))
      .max(32, t('forms.errors.max32CharactersLong'))
      .oneOf([Yup.ref('password')], t('forms.errors.confirmPassword'))
      .required(t('forms.errors.required')),
    firstName: yupString
      .min(2, t('forms.errors.twoToTwentyCharactersLong'))
      .max(20, t('forms.errors.twoToTwentyCharactersLong'))
      .required(t('forms.errors.required')),
    lastName: yupString
      .min(2, t('forms.errors.twoToTwentyCharactersLong'))
      .max(20, t('forms.errors.twoToTwentyCharactersLong'))
      .required(t('forms.errors.required')),
    email: yupString.email(t('forms.errors.email')).required(t('forms.errors.required')),
    // Check for all valid phone number types
    phoneNumber: yupString
      .matches(/(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g, t('forms.errors.phoneNumber'))
      .required(t('forms.errors.required')),
    verificationCode: yupString.required(t('forms.errors.required')),
    postalCode: Yup.string(t('forms.errors.validValue'))
      .matches(
        /[abceghj-nprstvxy]\d[abceghj-nprstv-z][\s-]?\d[abceghj-nprstv-z]\d/gi,
        t('forms.errors.postalCode')
      )
      .required(t('forms.errors.required')),
    yesNoOptions: yupString
      .oneOf(['yes', 'no'], t('forms.errors.required'))
      .required(t('forms.errors.required')),
    acceptTerms: yupBoolean
      .oneOf([true], t('forms.errors.acceptTerms'))
      .required(t('forms.errors.acceptTerms')),
    selectedProvinces: !yupString.notOneOf(['NA'], t('forms.errors.required')),
    bank: yupString.required(t('forms.errors.required')),
    otherBank: yupString.when('bank', {
      is: 'Other',
      then: yupString
        .min(2, t('forms.errors.min2CharactersLong'))
        .max(128, t('forms.errors.max128CharactersLong'))
        .matches(/^[_.@A-Za-z0-9\s-]*$/g, t('forms.errors.bankName'))
        .required(t('forms.errors.required')),
      displayed: true,
      otherwise: yupString.min(0),
    }),
    /**
     * @param {Number} min
     * @param {Number} max
     * @param {function} formatter
     */
    minMaxAmount: (min, max, formatter = null) =>
      yupNumber
        .min(
          min,
          t('forms.errors.minXAmount').replace(
            '{amount}',
            isFunction(formatter) ? formatter(min) : min.toString()
          )
        )
        .max(
          max,
          t('forms.errors.maxXAmount').replace(
            '{amount}',
            isFunction(formatter) ? formatter(max) : max.toString()
          )
        ),
    coinAmount: (coinBalance = 0, formatted = '') =>
      yupNumber
        .moreThan(0, t('forms.errors.validValue'))
        .max(
          coinBalance,
          t('forms.errors.maxXAmount').replace('{amount}', formatted || coinBalance.toString())
        ),
  }
}
