import getWindow from './getWindow'

const win = getWindow()

/**
 * @param adjusted
 */
export const top = (adjusted = 0) => {
  win.scrollTo({
    top: adjusted,
    behavior: 'smooth',
  })
}
