const aliases = {
  'en': [
    {
      'id': 'home',
      'alias':  '/'
    },
    {
      'id': 'healthcare-professionals',
      'alias': '/healthcare-professionals',
    },
    {
      'id': 'patients-consumers',
      'alias': '/patients-consumers'
    },
  ],
  'ca-fr': [
    {
      'id': 'home',
      'alias':  '/'
    },
    {
      'id': 'healthcare-professionals',
      'alias': '/professionnels-de-la-santé',
    },
    {
      'id': 'patients-consumers',
      'alias': '/patients-consommateurs'
    }
  ],
  'uk': [
    {
      'id': 'home',
      'alias':  '/'
    },
    {
      'id': 'healthcare-professionals',
      'alias': '/healthcare-professionals',
    },
    {
      'id': 'patients-consumers',
      'alias': '/patients-consumers'
    }
  ],
}

module.exports = { aliases }
