import React from 'react'
import * as PropTypes from 'prop-types'
// Components
import { Dialog, DialogContent } from '@material-ui/core'
// Assets
//import './_Modal.scss'

const Modal = ({
  content,
  onClose,
  className,
  submitError = '',
}) => {
  const fullWidth = true
  const open = true

  return (
    <Dialog className={`modal ${className}`} open={open} fullWidth={fullWidth} onClose={onClose}>
      <DialogContent className="modal__content">
        <div
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </DialogContent>
      {submitError && <div className="modal__error">{submitError}</div>}
    </Dialog>
  )
}

Modal.defaultProps = {
  content: '',
  onClose: null,
  className: '',
  submitError: ''
}

Modal.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: PropTypes.func,
  className: PropTypes.string,
  submitError: PropTypes.string
}

export default Modal
