import { useState, useCallback, useLayoutEffect } from 'react'
import debounce from 'lodash/debounce'

// Hook
function useWindowSize(delay = 500) {
  const isClient = typeof window === 'object'
  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }, [isClient])
  const [windowSize, setWindowSize] = useState(getSize())

  useLayoutEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    const debouncedHandleResize = debounce(handleResize, delay)
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [isClient, getSize])

  return windowSize
}

export default useWindowSize
