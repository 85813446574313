import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import getWindow from '../getWindow'

const inBrowser = getWindow('location') !== null

export default function useI18n() {
  const { t, i18n } = useTranslation()
  const { navigate: i18navigate, language, languages, defaultLanguage } = useI18next()

  /**
   * Allows navigation via a localized link against i18n.
   * @param to
   * @param options
   */
  const navigate = (to, options) => {
    // Only access if we're in a browser.
    if (inBrowser) {
      i18navigate(to, options)
    }
  }

  return { navigate, t, language, languages, defaultLanguage, i18n }
}
