const { aliases } = require('../../data/aliases')

/**
 *
 * @param url
 * @param lang
 * @returns {boolean|*|string}
 */
function getPageIdByUrl(url, lang) {
  if (!aliases[lang]) {
    return false
  }
  const currentAlias = aliases[lang].find((item) => item.alias === url)
  return currentAlias !== undefined && currentAlias.id ? currentAlias.id : 'home'
}

/**
 *
 * @param url
 * @param from
 * @param to
 * @returns {string|*}
 */
const translateUrl = (url, from, to) => {
  if (from === to) {
    if (to === 'en') {
      return url
    }

    return `/${to}${url}`
  }

  const pageId = getPageIdByUrl(decodeURI(url), from)
  const translatedAlias = aliases[to].find((item) => item.id === pageId)

  if (to === 'en') {
    return translatedAlias.alias
  }
  return `/${to}${translatedAlias.alias}`
}

module.exports = { translateUrl }
