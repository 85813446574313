import badgee from 'badgee'
import { once, noop, debounce } from 'lodash'
//import { siteOriginIs, siteTypes } from '../integration/api'

// Check if this is a dev site, or is not production mode in other cases.
const isEnabled = true /*siteOriginIs(siteTypes.dev, /https:\/\/pr(-[0-9]+)?\.d2ihqv3dbwu4tb.amplifyapp\.com/gi)*/

// Set up and configure these elements only once.
once(() => {
  badgee.config({
    enabled: isEnabled,
    styled: true,
  })

  badgee.style('white', {
    color: 'black',
    background: 'white',
    // 'font-size': '15px',
    'font-weight': 'bold',
    'border-radius': '2px',
    padding: '1px 3px',
    margin: '0 1px',
  })

  badgee.style('blue', {
    color: 'white',
    background: 'blue',
    // 'font-size': '15px',
    'font-weight': 'bold',
    'border-radius': '2px',
    padding: '1px 3px',
    margin: '0 1px',
  })
  badgee.style('yellow', {
    color: 'black',
    background: 'yellow',
    // 'font-size': '15px',
    'font-weight': 'bold',
    'border-radius': '2px',
    padding: '1px 3px',
    margin: '0 1px',
  })
  badgee.style('Cornsilk', {
    color: 'black',
    background: 'Cornsilk',
    // 'font-size': '15px',
    'font-weight': 'bold',
    'border-radius': '2px',
    padding: '1px 3px',
    margin: '0 1px',
  })
})()

export const consoleStyles = {
  auth: 'red',
  forms: 'orange',
  utils: 'white',
  hooks: 'blue',
  page: 'green',
  comp: 'yellow',
  store: 'Cornsilk',
}

const noOps = {
  log: noop,
  debug: noop,
  info: noop,
  warn: noop,
  error: noop,
}

export const consoleBadge = (label = '', style = '', disabled = false) => {
  let con = noOps

  if (isEnabled && !disabled) {
    con = label && style ? badgee.define(label, style) : badgee
  }

  return {
    log: con.log,
    debug: debounce(con.log, 400),
    info: con.info,
    warn: con.warn,
    error: con.error,
  }
}

// Export base as default
export default Object.freeze(consoleBadge())
